import {properties} from "../../../../environments/environment";
import {OpenaireEntities} from "./searchFields";

export class SearchFieldsBase {

  //main Entities
  //RESULTS
  //Used for datasets and publications and software and orp
  //In case Datasets or Software should display  different fields, use seperate tables for fields
  public RESULT_STATIC_FIELDS = ["resultbestaccessright", "type"];

  // "resultacceptanceyear",
  public RESULT_RANGE_FIELDS = [
    ["resultacceptanceyear", "resultacceptanceyear"]
  ];

  // Remove Collected From Filter "collectedfrom","collectedfrom"
  public RESULT_REFINE_FIELDS = [
    "instancetypename", "foslabel", "relfunder",
    "relfundinglevel0_id", "relfundinglevel1_id", "relfundinglevel2_id",
    "relproject", "sdg", "country", "resultlanguagename", "resulthostingdatasource", "community"];

  RESULT_FIELDS_ORDERED = [
    {type: "static", title: "", values: ["resultbestaccessright", "type"]},
    {type: "refine", title: "", values: ["instancetypename"]},
    {type: "range", title: "", values: ["resultacceptanceyear", "resultacceptanceyear"]},
    {type: "refine", title: "", values: [
      "foslabel", "relfunder",
      "relfundinglevel0_id", "relfundinglevel1_id", "relfundinglevel2_id",
      "relproject", "sdg", "country", "resultlanguagename", "resulthostingdatasource", "community"
    ]}
  ];

  public RESULT_ADVANCED_FIELDS: string[] = ["q", "resulttitle", "resultauthor", "authorid", "resultdescription", "resultsubject", "eoscifguidelines", "resultpublisher",
    "resultbestaccessright", "community","conceptname", "collectedfromdatasourceid", "resulthostingdatasourceid", "resultdateofacceptance",
    "relfunder",
    "relfundinglevel0_id", "relfundinglevel1_id", "relfundinglevel2_id",
    "resultlanguagename", "relorganizationid", "pid", "relprojectid", "instancetypename", "fos", "sdg"];
  public RESULT_FIELDS: { [key: string]: FieldDetails } = {
    ["q"]: {name: "Any field", type: "keyword", param: "q", operator: "op", equalityOperator: "=", filterType: null},
    ["resulttitle"]: {
      name: "Title",
      type: "keyword",
      param: "title",
      operator: "tt",
      equalityOperator: "=",
      filterType: null
    },
    ["resultauthor"]: {
      name: "Author",
      type: "keyword",
      param: "author",
      operator: "at",
      equalityOperator: "=",
      filterType: null
    },
    ["authorid"]: {
      name: "Author ORCID",
      type: "keyword",
      param: "orcid",
      operator: "oc",
      equalityOperator: " exact ",
      filterType: null
    },
    ["resultsubject"]: {
      name: "Subject",
      type: "keyword",
      param: "subject",
      operator: "sb",
      equalityOperator: "=",
      filterType: null
    },
    ["eoscifguidelines"]: {
      name: "EOSC Subject",
      type: "refine",
      param: "eoscSubj",
      operator: "es",
      equalityOperator: " exact ",
      filterType: null
    },
    ["resultdescription"]: {
      name: "Abstract",
      type: "keyword",
      param: "description",
      operator: "ds",
      equalityOperator: "=",
      filterType: null
    },
    ["resultpublisher"]: {
      name: "Publisher",
      type: "keyword",
      param: "publisher",
      operator: "pb",
      equalityOperator: "=",
      filterType: null
    },
    ["pid"]: {
      name: "PID",
      type: "keyword",
      param: "pid",
      operator: "pd",
      equalityOperator: " exact ",
      filterType: null
    },
    ["resulthostingdatasourceid"]: {
      name: "Hosting " + OpenaireEntities.DATASOURCE,
      type: "entity",
      param: "hostedBy",
      operator: "hs",
      equalityOperator: " exact ",
      filterType: null
    },
    ["resulthostingdatasource"]: {
      name: "Source",
      type: "refine",
      param: "hostedBy",
      operator: "hs",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
    ["instancetypename"]: {
      name: "Document Type",
      type: "vocabulary",
      param: "type",
      operator: "tp",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
    ["resultlanguagename"]: {
      name: "Language",
      type: "vocabulary",
      param: "lang",
      operator: "ln",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
    ["community"]: {
      name: OpenaireEntities.COMMUNITY,
      type: "refine",
      param: "community",
      operator: "cm",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
    ["conceptname"]: {
      name: "Subcommunity",
      type: "keyword",
      param: "conceptname",
      operator: "sc",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
    ["relproject"]: {
      name: "Project",
      type: "refine",
      param: "project",
      operator: "po",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
    ["relprojectid"]: {
      name: OpenaireEntities.PROJECT,
      type: "entity",
      param: "project",
      operator: "po",
      equalityOperator: " exact ",
      filterType: null
    },
    ["relfunder"]: {
      name: "Funder",
      type: "refine",
      param: "funder",
      operator: "fn",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
    ["relfundinglevel0_id"]: {
      name: "Funding Stream",
      type: "refine",
      param: "funderlv0",
      operator: "fn0",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
    ["relfundinglevel1_id"]: {
      name: "Funding Substream level 1",
      type: "refine",
      param: "funderlv1",
      operator: "fn1",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
    ["relfundinglevel2_id"]: {
      name: "Funding Substream level 2",
      type: "refine",
      param: "funderlv2",
      operator: "fn0",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
    ["resultacceptanceyear"]: {
      name: "Publication Date",
      type: "keyword",
      param: "year",
      operator: "ya",
      equalityOperator: " = ",
      filterType: null
    },
    ["resultdateofacceptance"]: {
      name: "Publication Date",
      type: "date",
      param: "date",
      operator: "dt",
      equalityOperator: " within ",
      filterType: null
    },
    ["resultacceptanceyear-range-resultacceptanceyear"]: {
      name: "Year range",
      type: "keyword",
      param: "year",
      operator: "ya",
      equalityOperator: " within ",
      filterType: "range"
    },
    ["resultbestaccessright"]: {
      name: "Access",
      type: "static",
      param: "access",
      operator: "ac",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
    ["collectedfrom"]: {
      name: "Collected From",
      type: "refine",
      param: "datasource",
      operator: "cl",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
    ["relorganizationid"]: {
      name: "Organization",
      type: "entity",
      param: "organization",
      operator: "og",
      equalityOperator: " exact ",
      filterType: null
    },
    ["collectedfromdatasourceid"]: {
      name: "Collected from " + OpenaireEntities.DATASOURCE,
      type: "entity",
      param: "collectedFrom",
      operator: "cl",
      equalityOperator: " exact ",
      filterType: null
    },
    ["resulttypeid"]: {
      name: "Type",
      type: "refine",
      param: "types",
      operator: "tp",
      equalityOperator: " exact ",
      filterType: "radio"
    },
    ["country"]: {
      name: "Country",
      type: "refine",
      param: "country",
      operator: "cu",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
    ["sdg"]: {
      name: "SDG [Beta]",
      type: "vocabulary",
      param: "sdg",
      operator: "sg",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
    ["fos"]: {
      name: "Field of Science",
      type: "vocabulary",
      param: "fos",
      operator: "fs",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
    ["foslabel"]: {
      name: "Field of Science",
      type: "vocabulary",
      param: "foslabel",
      operator: "fl",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
    ["type"]: {
      name: "Type",
      type: "static",
      param: "type",
      operator: "tp",
      equalityOperator: " = ",
      filterType: "checkbox"
    },
    ["peerreviewed"]: {
      name: "Peer reviewed",
      type: "triplet",
      param: "peerreviewed",
      operator: "pv",
      equalityOperator: " = ",
      filterType: "triplet"
    },
    ["isgreen"]: {
      name: "Green",
      type: "triplet",
      param: "isgreen",
      operator: "ig",
      equalityOperator: " = ",
      filterType: "triplet"
    },
    ["openaccesscolor"]: {
      name: "Publisher Access",
      type: "refine",
      param: "openaccesscolor",
      operator: "oc",
      equalityOperator: " exact ",
      filterType: "radio"
    },
    ["isindiamondjournal"]: {
      name: "In a Diamond OA journal",
      type: "triplet",
      param: "isindiamondjournal",
      operator: "dj",
      equalityOperator: " = ",
      filterType: "triplet"
    },
    ["publiclyfunded"]: {
      name: "Publicly funded",
      type: "triplet",
      param: "publiclyfunded",
      operator: "pf",
      equalityOperator: " = ",
      filterType: "triplet"
    },
    ["haslicense"]: {
      name: "License",
      type: "triplet",
      param: "haslicense",
      operator: "hl",
      equalityOperator: " = ",
      filterType: "triplet"
    },
    ["relorganization"]: {
      name: OpenaireEntities.ORGANIZATION,
      type: "refine",
      param: "organization",
      operator: "or",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
  };

  public RESULT_STATIC_FIELD_VALUES: { [key: string]: {"name": string, "id": string, "count": string}[] } = {
    ["resultbestaccessright"]: [
      { name: "Open Access", id: "Open Access", count: "0" },
      { name: "Closed Access", id: "Closed Access", count: "0" },
      { name: "Restricted", id: "Restricted", count: "0" },
      { name: "Open Source", id: "Open Source", count: "0" },
      { name: "Embargo", id: "Embargo", count: "0" }
    ],
    ["type"]: [
      { name: OpenaireEntities.PUBLICATIONS, id: "publications", count: "0" },
      { name: OpenaireEntities.DATASETS, id: "datasets", count: "0" },
      { name: OpenaireEntities.SOFTWARE, id: "software", count: "0" },
      { name: OpenaireEntities.OTHER, id: "other", count: "0" }
    ],
    ["isgreen"]: [
      { name: "All", id: "", count: "0" },
      { name: "Yes", id: "true", count: "0" },
      { name: "No", id: "false", count: "0" }
    ],
    ["isindiamondjournal"]: [
      { name: "All", id: "", count: "0" },
      { name: "Yes", id: "true", count: "0" },
      { name: "No", id: "false", count: "0" }
    ],
    ["peerreviewed"]: [
      { name: "All", id: "", count: "0" },
      { name: "Yes", id: "true", count: "0" },
      { name: "No", id: "false", count: "0" }
    ],
    ["publiclyfunded"]: [
      { name: "All", id: "", count: "0" },
      { name: "Yes", id: "true", count: "0" },
      { name: "No", id: "false", count: "0" }
    ],
    ["haslicense"]: [
      { name: "All", id: "", count: "0" },
      { name: "Yes", id: "true", count: "0" },
      { name: "No", id: "false", count: "0" }
    ]
  };

  //PROJECT
  public PROJECT_RANGE_FIELDS = [
    ["projectendyear", "projectstartyear"]
  ];
  public PROJECT_REFINE_FIELDS: string[] = ["funder", "fundinglevel0_id", "fundinglevel1_id",
    "fundinglevel2_id", "projectoamandatepublications", "projectstartyear", "projectendyear"];

  public PROJECT_FIELDS_ORDERED = [
    {type: "refine", title: "", values: ["funder", "fundinglevel0_id", "fundinglevel1_id", "fundinglevel2_id"]},
    {type: "range", title: "", values: ["projectstartyear", "projectendyear"]},
    {type: "refine", title: "", values: ["projectoamandatepublications"]}
  ];

  public PROJECT_ADVANCED_FIELDS: string[] = ["q", "projectacronym", "projecttitle", "projectkeywords",
    "funder", "fundinglevel0_id", "fundinglevel1_id", "fundinglevel2_id",
    "projectstartdate", "projectenddate",
    "projectcode_nt", "relorganizationid", "collectedfromdatasourceid"];
  public PROJECT_FIELDS: { [key: string]: FieldDetails } = {
    ["q"]: {name: "Any field", type: "keyword", param: "q", operator: "op", equalityOperator: "=", filterType: null},
    ["projectacronym"]: {
      name: "Acronym",
      type: "keyword",
      param: "acronym",
      operator: "ar",
      equalityOperator: "=",
      filterType: null
    },
    ["projecttitle"]: {
      name: "Title",
      type: "keyword",
      param: "title",
      operator: "tt",
      equalityOperator: "=",
      filterType: null
    },
    ["projectkeywords"]: {
      name: "Keyword",
      type: "keyword",
      param: "keywords",
      operator: "ky",
      equalityOperator: "=",
      filterType: null
    },

    ["funder"]: {
      name: "Funder",
      type: "refine",
      param: "funder",
      operator: "fn",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
    ["fundinglevel0_id"]: {
      name: "Funding Stream",
      type: "refine",
      param: "funderlv0",
      operator: "fn0",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
    ["fundinglevel1_id"]: {
      name: "Funding Substream level 1",
      type: "refine",
      param: "funderlv1",
      operator: "fn1",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
    ["fundinglevel2_id"]: {
      name: "Funding Substream level 2",
      type: "refine",
      param: "funderlv2",
      operator: "fn2",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
    ["projectstartyear"]: {
      name: "Start Year",
      type: "year",
      param: "startyear",
      operator: "sy",
      equalityOperator: " <= ",
      filterType: "checkbox"
    },
    ["projectendyear"]: {
      name: "End Year",
      type: "year",
      param: "endyear",
      operator: "ey",
      equalityOperator: " >= ",
      filterType: "checkbox"
    },
    ["projectendyear-range-projectstartyear"]: {
      name: "Active within",
      type: "year",
      param: "year",
      operator: "ya",
      equalityOperator: " = ",
      filterType: "range"
    },
    ["projectstartdate"]: {
      name: "Start Date",
      type: "date",
      param: "startdate",
      operator: "sd",
      equalityOperator: " within ",
      filterType: null
    },
    ["projectenddate"]: {
      name: "End Date",
      type: "date",
      param: "enddate",
      operator: "ed",
      equalityOperator: " within ",
      filterType: null
    },
    // ["projectecsc39"]: {
    //   name: "Special Clause 39",
    //   type: "boolean",
    //   param: "sc39",
    //   operator: "sc",
    //   equalityOperator: " exact ",
    //   filterType: "radio"
    // },
    ["projectoamandatepublications"]: {
      name: "OA " + OpenaireEntities.PUBLICATIONS + " Mandate",
      type: "boolean",
      param: "oapubl",
      operator: "oap",
      equalityOperator: " exact ",
      filterType: "radio"
    },
    ["projectoamandatedata"]: {
      name: "OA " + OpenaireEntities.DATASETS + " Mandate",
      type: "boolean",
      param: "oadata",
      operator: "oad",
      equalityOperator: " exact ",
      filterType: "radio"
    },
    ["projectcode_nt"]: {
      name: "Project Code",
      type: "keyword",
      param: "code",
      operator: "cd",
      equalityOperator: " exact ",
      filterType: null
    },
    ["relorganizationid"]: {
      name: "Organization",
      type: "entity",
      param: "organization",
      operator: "og",
      equalityOperator: " exact ",
      filterType: null
    },
    ["collectedfromdatasourceid"]: {
      name: "Collected from " + OpenaireEntities.DATASOURCE,
      type: "entity",
      param: "collectedFrom",
      operator: "cl",
      equalityOperator: " exact ",
      filterType: null
    }

  };

  //DATAPROVIDERS
  public DATASOURCE_STATIC_FIELDS = ["eosctype"];

// add Collected From Filter "collectedfromname"
  public DATASOURCE_REFINE_FIELDS: string[] = ["eoscdatasourcetype", "datasourceodlanguages", "datasourceodcontenttypes",
    "datasourcecompatibilityname", "country", "collectedfromname", "datasourcethematic",
    "datasourcejurisdiction"];
  public DATASOURCE_ADVANCED_FIELDS: string[] = ["q", "datasourceofficialname",
    "datasourceenglishname", "datasourceodsubjects", "datasourcetypename", "datasourceodlanguages",
    "datasourceodcontenttypes", "datasourcecompatibilityname", "relorganizationid", "collectedfromdatasourceid", "pid"];

  public DATASOURCE_FIELDS: { [key: string]: FieldDetails } = {
    ["q"]: {name: "Any field", type: "keyword", param: "q", operator: "op", equalityOperator: "=", filterType: null},
    ["datasourceofficialname"]: {
      name: "English name",
      type: "keyword",
      param: "officialname",
      operator: "of",
      equalityOperator: "=",
      filterType: null
    },
    ["datasourceenglishname"]: {
      name: "Title",
      type: "keyword",
      param: "engname",
      operator: "eg",
      equalityOperator: "=",
      filterType: null
    },
    ["datasourceodsubjects"]: {
      name: "Subject",
      type: "keyword",
      param: "subjects",
      operator: "sb",
      equalityOperator: "=",
      filterType: null
    },
    ["datasourcetypeuiid"]: {
      name: "Type",
      type: "refine",
      param: "type",
      operator: "tp",
      equalityOperator: " exact ",
      filterType: null
    },
    ["datasourcetypeuiname"]: {
      name: "Type",
      type: "refine",
      param: "type",
      operator: "tp",
      equalityOperator: " exact ",
      filterType: "radio"
    },
    ["eoscdatasourcetype"]: {
      name: "Type",
      type: "refine",
      param: "type",
      operator: "tp",
      equalityOperator: " exact ",
      filterType: "radio"
    },
    ["datasourcetypename"]: {
      name: "Type",
      type: "vocabulary",
      param: "type",
      operator: "tp",
      equalityOperator: " exact ",
      filterType: "radio"
    },
    ["datasourceodlanguages"]: {
      name: "Language",
      type: "vocabulary",
      param: "lang",
      operator: "ln",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
    ["datasourceodcontenttypes"]: {
      name: "Content",
      type: "refine",
      param: "content",
      operator: "cn",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
    ["datasourcecompatibilityid"]: {
      name: "Compatibility Level",
      type: "refine",
      param: "compatibility",
      operator: "cm",
      equalityOperator: " exact ",
      filterType: null
    },
    ["datasourcecompatibilityname"]: {
      name: "Compatibility Level",
      type: "vocabulary",
      param: "compatibility",
      operator: "cm",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
    ["relorganizationid"]: {
      name: "Organization",
      type: "entity",
      param: "organization",
      operator: "og",
      equalityOperator: " exact ",
      filterType: null
    },
    ["collectedfromname"]: {
      name: "Collected from",
      type: "entity",
      param: "collectedFrom",
      operator: "cl",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
    ["collectedfromdatasourceid"]: {
      name: "Collected from " + OpenaireEntities.DATASOURCE,
      type: "entity",
      param: "collectedFrom",
      operator: "cl",
      equalityOperator: " exact ",
      filterType: null
    },
    ["country"]: {
      name: "Country",
      type: "vocabulary",
      param: "country",
      operator: "cu",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
    ["datasourcethematic"]: {
      name: "Thematic",
      type: "boolean",
      param: "thematic",
      operator: "th",
      equalityOperator: " exact ",
      filterType: "radio"
    },
    ["datasourcejurisdiction"]: {
      name: "Jurisdiction",
      type: "vocabulary",
      param: "jurisdiction",
      operator: "ju",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
    ["pid"]: {name: "PID", type: "keyword", param: "pid", operator: "pd", equalityOperator: " exact ", filterType: null},
    ["eosctype"]: {
      name: "EOSC Type",
      type: "static",
      param: "type",
      operator: "tp",
      equalityOperator: " = ",
      filterType: "checkbox"
    }
  };

  public DATASOURCE_STATIC_FIELD_VALUES: { [key: string]: {"name": string, "id": string, "count": string}[] } = {
    ["eosctype"]: [
      { name: OpenaireEntities.DATASOURCES, id: "Data Source", count: "0" },
      { name: "Other "+OpenaireEntities.SERVICES, id: "Service", count: "0" },
    ]
  }

  public DEPOSIT_DATASOURCE_KEYWORD_FIELDS: { "name": string, "equalityOperator": string } [] = [
    {"name": "relorganizationname", "equalityOperator": "="},
    {"name": "relorganizationshortname", "equalityOperator": "="},
    {"name": "datasourceofficialname", "equalityOperator": "="},
    {"name": "datasourceenglishname", "equalityOperator": "="},
    {"name": "eoscdatasourcetype", "equalityOperator": " exact "},
    {"name": "country", "equalityOperator": " exact "},
    {"name": "datasourcesubject", "equalityOperator": " all "}
  ];
  public DEPOSIT_DATASOURCE_REFINE_FIELDS: string[] = ["datasourcetypename", "country", "datasourceodsubjects", "datasourceodcontenttypes", "datasourcecompatibilityname", "datasourcethematic", "datasourcejurisdiction"];

  public DEPOSIT_DATASOURCE_FIELDS: { [key: string]: FieldDetails } = {
    ["datasourcetypeuiname"]: {
      name: "Repository type",
      type: "refine",
      param: "type",
      operator: "tp",
      equalityOperator: " exact ",
      filterType: "radio"
    },
    ["eoscdatasourcetype"]: {
      name: "Repository type",
      type: "refine",
      param: "type",
      operator: "tp",
      equalityOperator: " exact ",
      filterType: "radio"
    },
    ["country"]: {
      name: "Countries",
      type: "vocabulary",
      param: "country",
      operator: "cu",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
    ["datasourceodsubjects"]: {
      name: "Subjects",
      type: "keyword",
      param: "subjects",
      operator: "sb",
      equalityOperator: "=",
      filterType: "checkbox"
    },
    ["datasourceodcontenttypes"]: {
      name: "Content type",
      type: "refine",
      param: "content",
      operator: "cn",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
    ["datasourcecompatibilityname"]: {
      name: "Compatibility Level",
      type: "vocabulary",
      param: "compatibility",
      operator: "cm",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
  };

  // public COMPATIBLE_DATAPROVIDER_FIELDS: string[] = ["datasourcetypename", "datasourcecompatibilityname"];
  public COMPATIBLE_DATAPROVIDER_FIELDS: string[] = ["datasourcetypename", "datasourceodlanguages", "datasourceodcontenttypes",
    "datasourcecompatibilityname", "country", "collectedfromname", "datasourcethematic",
    "datasourcejurisdiction"];
  // public ENTITY_REGISTRIES_FIELDS: string[] = ["datasourcetypename", "datasourcecompatibilityname"];
  public ENTITY_REGISTRIES_FIELDS: string[] = ["datasourcetypename", "datasourceodlanguages", "datasourceodcontenttypes",
    "datasourcecompatibilityname", "country", "collectedfromname", "datasourcethematic",
    "datasourcejurisdiction"];
  // public JOURNAL_FIELDS: string[] = ["datasourcetypename", "datasourcecompatibilityname"];
  public JOURNAL_FIELDS: string[] = ["datasourcetypename", "datasourceodlanguages", "datasourceodcontenttypes",
    "datasourcecompatibilityname", "country", "collectedfromname", "datasourcethematic",
    "datasourcejurisdiction"];


  //ORGANIZATION

  public ORGANIZATION_REFINE_FIELDS: string[] = ["countrynojurisdiction"]
  public ORGANIZATION_ADVANCED_FIELDS: string[] = ["q", "organizationlegalname", "organizationlegalshortname", "countrynojurisdiction", "pid"];

  public ORGANIZATION_FIELDS: { [key: string]: FieldDetails } = {
    ["q"]: {name: "Any field", type: "keyword", param: "q", operator: "op", equalityOperator: "=", filterType: null},
    ["organizationlegalname"]: {
      name: "Legal Name",
      type: "keyword",
      param: "name",
      operator: "nm",
      equalityOperator: "=",
      filterType: null
    },
    ["organizationlegalshortname"]: {
      name: "Legal Short Name",
      type: "keyword",
      param: "shortname",
      operator: "so",
      equalityOperator: "=",
      filterType: null
    },
    ["countrynojurisdiction"]: {
      name: "Country",
      type: "vocabulary",
      param: "country",
      operator: "cu",
      equalityOperator: " exact ",
      filterType: "checkbox"
    },
    ["pid"]: {name: "PID", type: "keyword", param: "pid", operator: "pd", equalityOperator: " exact ", filterType: null}
  };
  //  public ORGANIZATION_INDEX:string[] = ["organizationcountryname"]//,"organizationeclegalbody"];
  // public ADVANCED_SEARCH_ORGANIZATION_PARAM:string[] = ["q","contenttype","compatibility","country","type"];
  //  public ORGANIZATION_INDEX_PARAM_MAP:{ [key:string]:string } = {["organizationlegalname"]:"contenttype", ["organizationlegalshortname"]:"type",
  //   ["organizationcountryname"]:"country"};//,["organizationeclegalbody"]:"type"};
  // public ORGANIZATION_FIELDS_MAP: { [key:string]:{ name:string, operator:string, type:string, indexField:string , operator: "op", equalityOperator:string}} ={
  // ["q"]:{name:"Any field",operator:"op", type:"keyword", indexField:null, operator: "op", equalityOperator: "="},
  // ["contenttype"]:{name:"Legal Name",operator:"cn", type:"keyword" , indexField:"organizationlegalname", operator: "op", equalityOperator: "="},
  // ["compatibility"]:{name:"Legal Short Name",operator:"cm", type:"keyword", indexField:"organizationlegalshortname", operator: "op", equalityOperator: "="},
  // ["country"]:{name:"Country",operator:"cu", type:"vocabulary", indexField:"organizationcountryname", operator: "op", equalityOperator: " exact "},
  // ["type"]:{name:"Type",operator:"tp", type:"refine", indexField:"organizationeclegalbody", operator: "op", equalityOperator: " exact "},
  //
  // };


//add project field  depending on funder
  public HIDDEN_FIELDS: string[] = ["fundinglevel0_id", "fundinglevel1_id", "fundinglevel2_id",
    "relfundinglevel0_id", "relfundinglevel1_id", "relfundinglevel2_id", "relproject", "instancetypename",
    "isgreen", "openaccesscolor", "isindiamondjournal", "peerreviewed"
  ];

  public DEPENDENT_FIELDS: { [key: string]: string } = {
    ["relproject"]: "relfunder",
    ["fundinglevel0_id"]: "funder",
    ["fundinglevel1_id"]: "fundinglevel0_id",
    ["fundinglevel2_id"]: "fundinglevel1_id",
    ["relfundinglevel0_id"]: "relfunder",
    ["relfundinglevel1_id"]: "relfundinglevel0_id",
    ["relfundinglevel2_id"]: "relfundinglevel1_id",
    ["instancetypename"]: "type",
    // ["isgreen"]: "type"
  };

  public DEPENDENT_FIELDS_AND_VALUES: { [key: string]: { field: string, values: string[] } } = {
    ["isgreen"]: { field: "type", values: ["publications"] },
    ["openaccesscolor"]: { field: "type", values: ["publications"] },
    ["isindiamondjournal"]: { field: "type", values: ["publications"] },
    ["peerreviewed"]: { field: "type", values: ["publications"] },
  };

  public ADVANCED_SEARCH_OPERATORS: string[] = ["and", "or"];

  public COMMUNITIES_SEARCH_FIELDS: string[] = ["type", "access", "role"];

  public STAKEHOLDER_SEARCH_FIELDS: string[] = ["type", "access", "role"];

  constructor() {
  }

  getResultAdvancedFields() {
    // if(properties.environment == "production") {
    //   this.RESULT_ADVANCED_FIELDS = this.RESULT_ADVANCED_FIELDS.filter((value, index, array) => {
    //     return value != "eoscifguidelines";
    //   })
    // }
    return this.RESULT_ADVANCED_FIELDS;
  }

  getField(fieldId: string, fieldType: string): any {
    if (fieldType == "publication" || fieldType == "dataset" || fieldType == "software" || fieldType == "other" || fieldType == "result") {
      return this.RESULT_FIELDS[fieldId];
    } else if (fieldType == "project") {
      return this.PROJECT_FIELDS[fieldId];
    } else if (fieldType == "organization") {
      return this.ORGANIZATION_FIELDS[fieldId];
    } else if (fieldType == "datasource" || fieldType == "dataprovider") {
      return this.DATASOURCE_FIELDS[fieldId];
    } else if (fieldType == "service") {
      return this.DATASOURCE_FIELDS[fieldId];
    } else {
      return null;
    }
  }

  getFieldName(fieldId: string, fieldType: string): string {
    let field = this.getField(fieldId, fieldType);
    return field ? field.name : "UNDEFINED";
  }

  sortFieldsByName(fieldIds: string[], fieldIdsMap: { [key: string]: FieldDetails }) {
    fieldIds.sort((a: string, b: string) => {
      if (a == "q") {
        return -1;
      } else if (b == "q") {
        return 1;
      }
      let nameA: string = fieldIdsMap[a].name;
      let nameB: string = fieldIdsMap[b].name;
      return nameA.localeCompare(nameB);
    })
  }

  getFieldFilterType(fieldId: string, fieldType: string, usedBy: string = "search"): string {
    let field = this.getField(fieldId, fieldType);
    return field ? field.filterType : "checkbox";
  }

  getFieldParam(fieldId: string, fieldType: string): string {
    let field = this.getField(fieldId, fieldType);
    return field ? field.param : "UNDEFINED";
  }

  /*
  AND
    Funder: relfunder, relfundinglevel0_id, relfundinglevel1_id, relfundinglevel2_id
    Project: relproject
    Community: community
  OR
    Type: instancetypename, datasourcetypeuiname
    Language: resultlanguagename, datasourceodlanguages
    Compatibility Level: datasourcecompatibilityname
    Country: country
    Content: datasourceodcontenttypes
    Datasource: resulthostingdatasource
    Collected From: collectedfrom
  BULLETS
    Access Mode: resultbestaccessright
    Special Clause 39: projectecsc39
    Versioning:

  RANGE
    Publication date: resultacceptanceyear
    Project life: projectstartyear, projectendyear

  ? WHAT ABOUT ?:
    Subjects:
    Supported Identifiers:
  */

  getFieldOperator(fieldId: string): string {
    if (fieldId == "relfunder" || fieldId == "relfundinglevel0_id" || fieldId == "relfundinglevel1_id" || fieldId == "relfundinglevel2_id"
        || fieldId == "relproject" || fieldId == "community" || fieldId == "projectendyear-range-projectstartyear") {
      return "and";
    } else if (fieldId == "instancetypename" || fieldId == "eoscdatasourcetype"
        || fieldId == "resultlanguagename" || fieldId == "datasourceodlanguages"
        || fieldId == "datasourcecompatibilityname" || fieldId == "country" || fieldId == "countrynojurisdiction" || fieldId == "datasourceodcontenttypes"
        || fieldId == "resulthostingdatasource" || fieldId == "collectedfrom") {
      return "or";
    }
    return "or";
  }

  getFosParameter() {
    return this.RESULT_REFINE_FIELDS.includes("foslabel") ? "foslabel" : "fos";
  }
}

export class FieldDetails {
  name: string;
  type: string;
  param: string;
  equalityOperator: string;
  operator: string;
  filterType: string = "checkbox";
}
