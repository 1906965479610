import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SearchResult} from '../utils/entities/searchResult';
import {RefineResultsUtils} from './servicesUtils/refineResults.class';
import {Dates, DOI, StringUtils} from '../utils/string-utils.class';
import {ParsingFunctions} from '../landingPages/landing-utils/parsingFunctions.class';
import {EnvProperties} from '../utils/properties/env-properties';
import {map} from "rxjs/operators";
import {properties} from "../../../environments/environment";
import {HostedByCollectedFrom} from "../utils/result-preview/result-preview";


@Injectable()
export class SearchResearchResultsService {
  public parsingFunctions: ParsingFunctions = new ParsingFunctions();

  constructor(private http: HttpClient = null) {
  }


  search(resultType: string, params: string, refineParams: string, page: number, size: number, sortBy: string, refineFields: string[], properties: EnvProperties): any {
    let link = properties.searchAPIURLLAst + this.getEntityQueryName(resultType, true);

    let url = link + "?";
    if (params != null && params != '') {
      url += params;
    }
    if (refineParams != null && refineParams != '') {
      url += refineParams;
    }
    if (sortBy) {
      url += "&sortBy=" + sortBy;
    }
    url += "&page=" + (page - 1) + "&size=" + size + "&format=json";

    return this.http.get((properties.useCache) ? (properties.cacheUrl + encodeURIComponent(url)) : url)
        .pipe(map(res => [res['meta'].total, this.parseResults(resultType, res['results'], properties), RefineResultsUtils.parse(res['refineResults'], refineFields, "publication")]));
  }

  searchById(resultType: string, id: string, properties: EnvProperties): any {
    let url = properties.searchAPIURLLAst + this.getEntityQueryName(resultType, true) + "/" + id + "?format=json";

    return this.http.get((properties.useCache) ? (properties.cacheUrl + encodeURIComponent(url)) : url)
        .pipe(map(res => this.parseResults(resultType, res, properties)));
  }

  searchAggregators(resultType: string, id: string, params: string, refineParams: string, page: number, size: number, properties: EnvProperties): any {
    let link = properties.searchAPIURLLAst + this.getEntityQueryName(resultType, true);

    let url = link + "?" + "&format=json";
    if (params != null && params != '') {
      url += params;
    }
    if (refineParams != null && refineParams != '') {
      url += refineParams;
    }
    url += "&page=" + (page - 1) + "&size=" + size;

    return this.http.get((properties.useCache) ? (properties.cacheUrl + encodeURIComponent(url)) : url)
        .pipe(map(res => this.parseRefineResults(id, res['refineResults'])));
  }

  searchByListOfDOI(resultType: string, DOIs: string[], refineParams: string, page: number, size: number, refineFields: string[], properties: EnvProperties): any {
    let link = properties.searchAPIURLLAst + this.getEntityQueryName(resultType, true);

    let url = link + "?" + "&format=json&";
    var doisParams = "";

    for (var i = 0; i < DOIs.length; i++) {
      doisParams += (doisParams.length > 0 ? "&" : "") + 'doi="' + DOIs[i] + '"';
    }
    if (doisParams.length > 0) {
      url += "&" + doisParams;

    }
    if (refineParams != null && refineParams != '') {
      url += refineParams;
    }
    url += "&page=" + (page - 1) + "&size=" + size;

    return this.http.get((properties.useCache) ? (properties.cacheUrl + encodeURIComponent(url)) : url)
        .pipe(map(res => [res['meta'].total, this.parseResults(resultType, res['results'], properties), RefineResultsUtils.parse(res['refineResults'], refineFields, "publication")]));
  }

  advancedSearch(resultType: string, params: string, page: number, size: number, sortBy: string, properties: EnvProperties, refineParams: string = null, refineFields: string[] = null, refineQuery: string = null): any {
    let url = properties.searchResourcesAPIURL;
    var basicQuery = "(oaftype exact result) and (resulttypeid exact " + this.getEntityQueryName(resultType, false) + ") ";
    url += "?query=";
    if (params != null && params != '') {
      url += " ( " + basicQuery + " ) " + " and (" + params + ")";
    } else {
      url += " ( " + basicQuery + " ) ";
    }
    if (refineParams != null && refineParams != '') {
      url += refineParams;
    }
    if (sortBy) {
      let sortOptions = sortBy.split(",");
      url += "sortBy " + sortOptions[0] + "/sort." + sortOptions[1] + " ";
    }
    if (refineQuery) {
      url += "&" + refineQuery;
    }

    url += "&page=" + (page - 1) + "&size=" + size;
    url += "&format=json";

    return this.http.get((properties.useCache) ? (properties.cacheUrl + encodeURIComponent(url)) : url)
        .pipe(map(res => [res['meta'].total, this.parseResults(resultType, res['results'], properties), RefineResultsUtils.parse(res['refineResults'], refineFields, "publication")]));
  }

  advancedSearchResults(resultType: string, params: string, page: number, size: number, sortBy: string, properties: EnvProperties, refineParams: string = null, refineFields: string[] = null, refineQuery: string = null, minRef: boolean = false): any {
    let url = properties.searchAPIURLLAst + "resources2/?format=json";
    if (params != null && params != '') {
      url += "&query=(" + params + ")";
    }
    if (sortBy) {
      let sortOptions = sortBy.split(",");
      url += (params ? " " : "&query=(*) ") + "sortBy " + sortOptions[0] + "/sort." + sortOptions[1] + (params ? " " : " ");
    }
    if (refineParams != null && refineParams != '') {
      url += refineParams;
    }
    if (refineQuery) {
      url += "&" + refineQuery;
    }

    url += "&page=" + (page - 1) + "&size=" + size;
    url += minRef ? "&minRef=true" : "";
    // url += "&format=json";

    // return this.http.get((properties.useCache) ? (properties.cacheUrl + encodeURIComponent(url)) : url)
    return this.http.get((properties.useLongCache && size == 0 && !params && (!refineQuery || !refineQuery.includes("fq="))) ? (properties.cacheUrl + encodeURIComponent(url)) : url)
        .pipe(map(res => [res['meta'].total, this.parseResults(resultType, res['results'], properties), RefineResultsUtils.parse(res['refineResults'], refineFields, "publication")]));
  }
 countResults(field:string,value:string): any {
    let url = properties.utilsService + "/portals/countResults";
    if (field && value) {
      url += "?field=" + encodeURIComponent(field) + "&value="+encodeURIComponent(value);
    }

    return this.http.get((properties.useLongCache  ) ? (properties.cacheUrl + encodeURIComponent(url)) : url);
  }
  searchResultForEntity(resultType: string, params: string, page: number, size: number, properties: EnvProperties): any {
    let link = properties.searchAPIURLLAst;
    //let url = link+params+"/"+this.getEntityQueryName(resultType,true)+ "?format=json";
    //url += "&page="+(page-1)+"&size="+size;
    //url += "&sortBy=resultdateofacceptance,descending";

    //let url = link+"/resources2?format=json&query="+params+" sortBy resultdateofacceptance/sort.descending&type="+this.getEntityQueryName(resultType,true);

    let url = link + "/" + this.getEntityQueryName(resultType, true);
    url += "?format=json";
    url += "&fq=" + params;
    url += "&sortBy=resultdateofacceptance,descending";
    url += "&page=" + (page - 1) + "&size=" + size;

    return this.http.get((properties.useCache) ? (properties.cacheUrl + encodeURIComponent(url)) : url)
        .pipe(map(res => [res['meta'].total, this.parseResults(resultType, res['results'], properties)]));
  }

//???? why different from above?
  searchForDataproviders(resultType: string, params: string, page: number, size: number, properties: EnvProperties): any {
    let link = properties.searchAPIURLLAst;
    let url = link + params;
    url += "&sortBy=resultdateofacceptance,descending";
    url += "&page=" + (page - 1) + "&size=" + size + "&format=json";
    return this.http.get((properties.useCache) ? (properties.cacheUrl + encodeURIComponent(url)) : url)
        .pipe(map(res => [res['meta'].total, this.parseResults(resultType, res['results'], properties)]));
  }

  searchForMyOrcidLinks(resultType: string, orcidQuery: string, typeQuery: string, page: number, size: number): any {
    let url = properties.searchAPIURLLAst + "resources2/?format=json";
    if (orcidQuery != null && orcidQuery != '') {
      url += "&query=(" + orcidQuery + ")";
    }
    url += typeQuery;
    url += "&page=" + (page - 1) + "&size=" + size;

    return this.http.get((properties.useCache) ? (properties.cacheUrl + encodeURIComponent(url)) : url)
        .pipe(map(res => [res['meta'].total, this.parseResults(resultType, res['results'], properties)]));
  }

  parseResults(resultType: string, data: any, properties: EnvProperties): SearchResult[] {
    let results: SearchResult[] = [];
    if (data == null) {
      return results;
    }

    let length = Array.isArray(data) ? data.length : 1;

    for (let i = 0; i < length; i++) {
      let resData = Array.isArray(data) ? data[i]['result']['metadata']['oaf:entity']['oaf:result'] : data['result']['metadata']['oaf:entity']['oaf:result'];

      var result: SearchResult = new SearchResult();
      if (resData['resulttype']) {
        result.entityType = resData['resulttype']['classname'];
      } else {
        result.entityType = resultType;
      }

      result['title'] = {"name": '', "accessMode": ''};

      if (Array.isArray(resData['title'])) {
        for (let i = 0; i < resData['title'].length; i++) {
          if (resData['title'][i] && resData['title'][i].content) {
            if (!result.title.name || resData['title'][i].classid == "main title") {
              result['title'].name = StringUtils.HTMLToString(String(resData['title'][i].content));
            }
            if (resData['title'][i].classid == "main title") {
              break;
            }
          }
        }
        if (!result.title.name) {
          result['title'].name = "";
        }
        // result['title'].name = (resData['title'][0] && resData['title'][0].content) ? String(resData['title'][0].content) : "";
      } else {
        result['title'].name = (resData['title'] && resData['title'].content) ? StringUtils.HTMLToString(String(resData['title'].content)) : "";
      }

      if (resData['bestaccessright'] && resData['bestaccessright'].hasOwnProperty("classname")) {
        result['title'].accessMode = resData['bestaccessright'].classname;
      }

      result.types = new Array<string>();
      let types = new Set<string>();

      let instance;
      let length = Array.isArray(resData['children']['instance']) ? resData['children']['instance'].length : 1;
      result.hostedBy_collectedFrom = new Array<HostedByCollectedFrom>();
      for (let i = 0; i < length; i++) {
        instance = Array.isArray(resData['children']['instance']) ? resData['children']['instance'][i] : resData['children']['instance'];
        this.parsingFunctions.parseTypes(result.types, types, instance);
        if(instance?.hasOwnProperty("hostedby")) {
          if(instance.hasOwnProperty("webresource")) {
            let url = Array.isArray(instance['webresource'])?instance['webresource'][0].url:instance['webresource'].url;
            if(url.includes('&amp;')) {
              url = url.replace(/&amp;/gmu, '&');
            }
            if(instance.hasOwnProperty("hostedby")) {
              this.parsingFunctions.parseHostedBy_collectedFrom(result.hostedBy_collectedFrom, instance, url, result.title.accessMode);
            }
          }
        }
      }
      result.hostedBy_collectedFrom.sort(this.parsingFunctions.compareHostedByCollectedFrom);

      // Measure
      result.measure = this.parsingFunctions.parseMeasures(resData['measure']);
      /////////////////////////// Athena Code ///////////////////////////
      if (resData['pid']) {
        if (!Array.isArray(resData['pid'])) {
          if (resData['pid'].classid && resData['pid'].classid == 'doi') {
            if (resData['pid'].content != '' && resData['pid'].content != null) {
              result.DOIs.push((resData['pid'].content + "").replace("https://doi.org/", ""));
            }
          }
        } else {
          for (let i = 0; i < resData['pid'].length; i++) {
            if (resData['pid'][i].classid == 'doi') {
              if (resData['pid'][i].content != '' && resData['pid'][i].content != null && resData['pid'][i].content) {
                result.DOIs.push((resData['pid'][i].content + "").replace("https://doi.org/", ""));
              }
            }
          }
        }
        result.identifiers = this.parsingFunctions.parseIdentifiers(resData['pid']);
      }
      /////////////////////////// Athena Code ///////////////////////////
      if (resData['programmingLanguage'] && resData['programmingLanguage'] != null) {
        result.programmingLanguages = new Array<string>();

        if (!Array.isArray(resData['programmingLanguage'])) {
          if (resData['programmingLanguage'].classname != "Undetermined" && resData['programmingLanguage'].classname) {
            result.programmingLanguages.push(resData['programmingLanguage'].classname);
          }
        } else {
          for (let i = 0; i < resData['programmingLanguage'].length; i++) {
            if (resData['programmingLanguage'][i].classname != "Undetermined" && resData['programmingLanguage'][i].classname) {
              result.programmingLanguages.push(resData['programmingLanguage'][i].classname);
            }
          }
        }
      }

      if (resData['language'] && resData['language'] != null) {
        result.languages = new Array<string>();

        if (!Array.isArray(resData['language'])) {
          if (resData['language'].classname != "Undetermined" && resData['language'].classname) {
            result.languages.push(resData['language'].classname);
          }
        } else {
          for (let i = 0; i < resData['language'].length; i++) {
            if (resData['language'][i].classname != "Undetermined" && resData['language'][i].classname) {
              result.languages.push(resData['language'][i].classname);
            }
          }
        }
      }

      if (resData['country'] && resData['country'] != null) {
        result.countriesForResults = new Array<string>();

        if (!Array.isArray(resData['country'])) {
          if (resData['country'].classname != "Undetermined" && resData['country'].classname) {
            result.countriesForResults.push(resData['country'].classname);
          }
        } else {
          for (let i = 0; i < resData['country'].length; i++) {
            if (resData['country'][i].classname != "Undetermined" && resData['country'][i].classname) {
              result.countriesForResults.push(resData['country'][i].classname);
            }
          }
        }
      }

      result['id'] = Array.isArray(data) ? data[i]['result']['header']['dri:objIdentifier'] : data['result']['header']['dri:objIdentifier'];
      result['objId'] = result['id'];
      let canId = ParsingFunctions.parseRelCanonicalId(Array.isArray(data) ? data[i] : data, "result");
      if (canId) {
        result['id'] = canId;
      }
      result['relcanId'] = result['id'];

      if (resData['rels'].hasOwnProperty("rel")) {
        let relLength = Array.isArray(resData['rels']['rel']) ? resData['rels']['rel'].length : 1;

        for (let j = 0; j < relLength; j++) {
          let relation = Array.isArray(resData['rels']['rel']) ? resData['rels']['rel'][j] : resData['rels']['rel'];

          if (relation.hasOwnProperty("to")) {
            if (relation['to'].class && relation['to'].class.toLowerCase() == "isproducedby") {
              result['projects'] = this.parseProjects(result['projects'], relation);
            }
          }
        }
      }

      if (resData.hasOwnProperty("creator") && resData['creator'] != null) {
        if (result['authors'] == undefined) {
          result['authors'] = new Array<{ "fullName": string, "orcid": string, "orcid_pending": string }>();
        }

        let authors = resData['creator'];
        let length = Array.isArray(authors) ? authors.length : 1;

        for (let i = 0; i < length; i++) {
          let author = Array.isArray(authors) ? authors[i] : authors;
          if (author) {
            if (author.orcid) {
              author.orcid = author.orcid.toUpperCase();
            }
            if (author.orcid_pending) {
              author.orcid_pending = author.orcid_pending.toUpperCase();
            }

            if(result['authors'][author.rank] && result['authors'][author.rank].fullName == author.content) {
              if(!author.orcid && result['authors'][author.rank].orcid) {
                author.orcid = result['authors'][author.rank].orcid;
              } else if(!author.orcid_pending && result['authors'][author.rank].orcid_pending) {
                author.orcid_pending = result['authors'][author.rank].orcid_pending;
              }
            }

            result['authors'][author.rank] = {
              "fullName": author.content,
              "orcid": author.orcid,
              "orcid_pending": author.orcid_pending
            };
          }
        }
        result.authors = result.authors.filter(function (item) {
          return (item != undefined && item.fullName != undefined);
        });
      }

      var date: string = (resData.dateofacceptance ? resData.dateofacceptance : '') + ''; // transform to string in case it is an integer
      result.year = (date && (date).indexOf('-') !== -1) ? date.split('-')[0] : date;

      let abstracts = this.parsingFunctions.parseDescription(resData.description, true);
      result.description = abstracts;
      // if (result.description && result.description.length > this.sizeOfDescription) {
      //   result.description = result.description.substring(0, this.sizeOfDescription) + "...";
      // }

      if (resData.embargoenddate && resData.embargoenddate != '') {
        result.embargoEndDate = Dates.getDate(resData.embargoenddate);
      }

      if (!Array.isArray(resData.publisher)) {
        result.publisher = resData.publisher;
      } else {
        for (let i = 0; i < resData.publisher.length; i++) {
          if (result.publisher != undefined) {
            result.publisher += ', ' + resData['publisher'][i];
          } else {
            result.publisher = resData['publisher'][i];
          }
        }
      }
      if (resData['context'] != null) {
        result.enermapsId = ParsingFunctions.getEnermapsConceptId(this.parsingFunctions.parseContexts(resData['context']));
      }
      if (resData.dateofacceptance && resData.dateofacceptance != null) {
        let date: string = (resData.dateofacceptance ? resData.dateofacceptance : '') + ''; // transform to string in case it is an integer
        result.date = (date && (date).indexOf('-') !== -1) ? date.split('-')[0] : date;
        result.dateofacceptance = resData.dateofacceptance ? Dates.getDate(resData.dateofacceptance) : null;
      }
      if (resData.journal && resData.journal != null) {
        result.journal = {
          "journal": "",
          "issn": "",
          "lissn": "",
          "eissn": "",
          "issue": "",
          "volume": "",
          "start_page": "",
          "end_page": ""
        }
        result.journal['journal'] = resData.journal.content;
        result.journal['issn'] = resData.journal.issn;
        result.journal['lissn'] = resData.journal.lissn;
        result.journal['eissn'] = resData.journal.eissn;
        result.journal['issue'] = resData.journal.iss;
        result.journal['volume'] = resData.journal.vol;
        result.journal['start_page'] = resData.journal.sp;
        result.journal['end_page'] = resData.journal.ep;
      }

      result.hostedBy_collectedFrom = this.parsingFunctions.addPublisherToHostedBy_collectedFrom(
        result.hostedBy_collectedFrom, result.publisher,
        result['journal']?result['journal'].journal:null, result.identifiers);


      if(resData.hasOwnProperty("publiclyfunded") && resData.publiclyfunded) {
        result.publiclyFunded = resData.publiclyfunded;
      }
      if((resData.hasOwnProperty("isgreen") && resData.isgreen)
        || (resData.hasOwnProperty("openaccesscolor") && resData.openaccesscolor)
        || (resData.hasOwnProperty("isindiamondjournal") && resData.isindiamondjournal)) {
        result.oaRoutes = {
          "green": resData.isgreen,
          "oaColor": resData.openaccesscolor,
          "isInDiamondJournal":resData.isindiamondjournal
        };
      }

      results.push(result);
    }
    return results;
  }

  parseProjects(projects: {
    "id": string, "acronym": string, "title": string,
    "funderShortname": string, "funderName": string,
    "code": string
  }[], relation: any): {
    "id": string, "acronym": string, "title": string,
    "funderShortname": string, "funderName": string,
    "code": string
  }[] {
    if (projects == undefined) {
      projects = new Array<{
        "id": string, "acronym": string, "title": string,
        "funderShortname": string, "funderName": string,
        "code": string
      }>();
    }

    let countProjects = projects.length;

    projects[countProjects] = {
      "id": "", "acronym": "", "title": "",
      "funderShortname": "", "funderName": "",
      "code": ""
    };

    if (relation.title != 'unidentified') {
      projects[countProjects]['id'] = relation['to'].content;
      projects[countProjects]['acronym'] = relation.acronym;
      projects[countProjects]['title'] = relation.title;
      projects[countProjects]['code'] = relation.code;
    } else {
      projects[countProjects]['id'] = "";
      projects[countProjects]['acronym'] = "";
      projects[countProjects]['title'] = "";
      projects[countProjects]['code'] = "";
    }

    if (relation.hasOwnProperty("funding")) {
      let fundingLength = Array.isArray(relation['funding']) ? relation['funding'].length : 1;

      for (let z = 0; z < fundingLength; z++) {
        let fundingData = Array.isArray(relation['funding']) ? relation['funding'][z] : relation['funding'];

        if (fundingData.hasOwnProperty("funder")) {
          projects[countProjects]['funderShortname'] = fundingData['funder'].shortname;
          projects[countProjects]['funderName'] = fundingData['funder'].name;
        }
      }
    }

    return projects;
  }

  parseRefineResults(id: string, data: any): any {
    var results: any = [];
    if (data.hasOwnProperty("resulthostingdatasource")) {
      let length = Array.isArray(data['resulthostingdatasource']) ? data['resulthostingdatasource'].length : 1;

      for (let i = 0; i < length; i++) {
        let datasource = Array.isArray(data['resulthostingdatasource']) ? data['resulthostingdatasource'][i] : data['resulthostingdatasource'];

        let result: { "name": string, "id": string, "count": number } = {"name": "", "id": "", "count": 0};
        result['name'] = datasource.name;
        result['id'] = datasource.id.split("||")[0];
        result['count'] = datasource.count;

        if (result['id'] != id && result['name'] != "Unknown Repository") {
          results.push(result);
        }
      }
    }
    return results;
  }

  private numOfResults(url: string, properties: EnvProperties): any {
    return this.http.get((properties.useCache) ? (properties.cacheUrl + encodeURIComponent(url)) : url)
        .pipe(map(res => res['total']));
  }

  numOfEntityResults(resultType: string, id: string, entity: string, properties: EnvProperties): any {
    var parameters: string = "";
    parameters = this.getEntityQueryName(entity, true) + "/" + id + "/" + this.getEntityQueryName(resultType, true) + "/count";
    let url = properties.searchAPIURLLAst + parameters + "?format=json";
    return this.numOfResults(url, properties);
  }

  numOfResearchOutcomes(params: string, properties: EnvProperties, refineParams: string = null): any {
    let url = properties.searchAPIURLLAst + "resources2/?format=json&size=0&type=results";
    if (params.length > 0) {
      // var DOIs:string[] = DOI.getDOIsFromString(params);
      // var doisParams = "";
      //
      // for(var i =0 ;i < DOIs.length; i++){
      //   doisParams+=(doisParams.length > 0?"&":"")+'doi="'+ DOIs[i]+'"';
      // }
      // if(doisParams.length > 0){
      //   url += "&"+doisParams;
      // }else{
      //   url += "&query=" + StringUtils.URIEncode(params);
      // }
      url += "&query=" + params;
    }

    if (refineParams != null && refineParams != '') {
      url += refineParams;
    }
    return this.http.get((properties.useCache) ? (properties.cacheUrl + encodeURIComponent(url)) : url)
        .pipe(map(res => res['meta']['total']));
  }

  numOfSearchResults(resultType: string, params: string, properties: EnvProperties, refineParams: string = null): any {
    let url = properties.searchAPIURLLAst + this.getEntityQueryName(resultType, true) + "/count?format=json";
    if (params.length > 0) {
      var DOIs: string[] = DOI.getDOIsFromString(params);
      var doisParams = "";

      for (var i = 0; i < DOIs.length; i++) {
        doisParams += (doisParams.length > 0 ? "&" : "") + 'doi="' + DOIs[i] + '"';
      }
      if (doisParams.length > 0) {
        url += "&" + doisParams;
      } else {
        url += "&q=" + StringUtils.URIEncode(params);
      }
    }

    if (refineParams != null && refineParams != '') {
      url += refineParams;
    }
    return this.numOfResults(url, properties);
  }

  numOfSearchResultsLinkedToPub(resultType: string, properties: EnvProperties): any {
    let url = properties.searchAPIURLLAst + "resources?query=" + encodeURIComponent("( (oaftype exact result) and (resulttypeid exact " + resultType + ") and (relresulttype=publication)  )") + "&page=0&size=0&format=json";
    return this.http.get((properties.useCache) ? (properties.cacheUrl + encodeURIComponent(url)) : url)
        .pipe(map(res => res['meta']['total']));
  }

  countTotalResults(resultType: string, properties: EnvProperties, refineParams: string = null): any {
    let url = properties.searchAPIURLLAst + this.getEntityQueryName(resultType, true) + "/count?format=json" + refineParams;
    return this.numOfResults(url, properties);
  }

  /*
      private quote(word: any): string {
          return '"'+word+'"';
      }
  */

  private getEntityQueryName(entityType: string, plural: boolean) {
    if (entityType == "publication" || entityType == "dataset" || entityType == "organization" || entityType == "datasource" || entityType == "project") {
      if (plural) {
        return entityType + "s";
      } else {
        return entityType;
      }
    } else {
      return entityType;
    }
  }

  public countCollectedResultsWithFundingInfo(datasourceId: string) {
    let url = properties.searchAPIURLLAst + "resources?query=" + encodeURIComponent("(oaftype=result and collectedfromdatasourceid exact \"" + datasourceId + "\" and relprojectid=*)") + "&page=0&size=0&format=json";
    return this.http.get((properties.useCache) ? (properties.cacheUrl + encodeURIComponent(url)) : url)
        .pipe(map(res => res['meta']['total']));
  }
  fetchByDOIs(DOIs:string[],query:string): any {
    let url = properties.searchAPIURLLAst + "/researchProducts/byDoi?type=publications" + (query?query:"");
    return this.http.post(url, {doiArray: DOIs})
      .pipe(map(res => [res['meta'].total, this.parseResults("result", res['results'], properties), RefineResultsUtils.parse(res['refineResults'], null, "publication")]));
  }
}
